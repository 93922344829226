import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { Cloudinary } from "@cloudinary/url-gen"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/sharp-light-svg-icons"

const CloudinaryVideoThumbnail = ({
  videoFilename,
  aspectRatio,
  variant = "slide",
}) => {
  const variantSettings = {
    slide: {
      fillColor: "ffffff",
    },
    thumb: {
      fillColor: null,
    },
  }
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.CLOUDINARY_NAME,
    },
  })

  const stillImage = cld.video(videoFilename).format("jpg").toURL()
  return (
    <div className="relative block w-full">
      <Image
        fillColor={variantSettings[variant].fillColor}
        aspectRatio={aspectRatio}
        className="w-full h-auto duration-300 transform group-hover:scale-102"
        image={stillImage}
        lazyLoad={false}
      />
      <div
        className={`${
          variant === "slide" &&
          "h-20 w-20 p-4 pl-6 md:h-28 md:w-28 md:p-7 md:pl-9"
        } ${
          variant === "thumb" && "h-10 w-10 p-3 pl-4"
        } bg-ro-gray-900-50o flex justify-center items-center rounded-full absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`}
      >
        <FontAwesomeIcon
          icon={faPlay}
          size="1x"
          className="text-ro-white h-full w-full"
        />
      </div>
    </div>
  )
}

export default CloudinaryVideoThumbnail
