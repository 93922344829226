import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import FluidHeadline from "../atoms/FluidHeadline"

const PromoBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="container"
          showHeadline="false"
        >
          <div className="relative -mx-4 overflow-hidden lg:-mx-10 bg-ro-white">
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="object-cover w-full h-auto aspect-[12/5]"
              adaptToPixelRatio={true}
            />
            <div
              className={`inset-0 bg-ro-gray-900 lg:absolute lg:bg-transparent ${
                block.no_gradient ? "" : "hero-gradient"
              }`}
            >
              <div className="h-full px-4 py-8 mx-auto lg:px-10">
                <div className="flex flex-col justify-between h-full lg:w-1/2">
                  <FluidHeadline
                    className="text-ro-white "
                    headlineLevel="04"
                    headline={block.headline}
                  />
                  <div>
                    <Richtext className="text-ro-white" text={block.text} />
                    {block.buttons && block.buttons.length > 0 && (
                      <div className="mt-8 -mb-4">
                        {block.buttons.map((button, index) => (
                          <Button
                            key={index}
                            className={`headerbutton-ga4 flex w-full md:inline-block md:w-fit mb-4 ${
                              index + 1 < block.buttons.length ? "mr-4" : ""
                            }`}
                            btnType={index === 0 ? "primary" : "outlineWhite"}
                            link={button.link}
                            target={button.target}
                          >
                            {button.link_text}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default PromoBlock
