import React from "react"

import { Dialog, Transition } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-light-svg-icons"

const Drawer = ({ children, setIsOpen, isOpen = false }) => {
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Transition
      show={isOpen}
      appear={true}
      as={React.Fragment}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300 delay-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => closeModal()}
      >
        <Dialog.Overlay className="fixed inset-x-0 top-0 bottom-0 flex bg-ro-gray-900-50o">
          <Transition.Child
            as={React.Fragment}
            enter="transition-all transform duration-300 delay-200"
            enterFrom="translate-y-full md:translate-y-0 md:translate-x-full"
            enterTo="translate-y-0 md:translate-x-0"
            leave="transition-all transform duration-300"
            leaveFrom="translate-y-0 md:translate-x-0"
            leaveTo="translate-y-full md:translate-y-0 md:translate-x-full"
          >
            <div className="absolute bottom-0 md:inset-y-0 right-0 w-full h-[calc(100vh-3rem)] md:h-screen bg-ro-white md:w-1/2 xl:w-1/3 2xl:w-[28%]">
              <div className="">
                <button
                  className="flex items-center justify-center w-12 h-12 ml-auto px-grid hover:bg-ro-gray-75 active:bg-ro-gray-200"
                  onClick={() => closeModal()}
                >
                  <FontAwesomeIcon icon={faXmark} size="1x" />
                </button>
              </div>
              <div className="h-[calc(100vh-3rem)] px-4 md:px-12 overflow-y-auto">
                {children}
              </div>
            </div>
          </Transition.Child>
        </Dialog.Overlay>
      </Dialog>
    </Transition>
  )
}

export default Drawer
