import React, { useState, useEffect } from "react"
import NexmartPanel from "./NexmartPanel"
import DealerFinder from "./DealerFinder"

import { useTranslation } from "react-i18next"

const BuyProductPanel = ({
  sku,
  suppressNexmart = false,
  startAtTab = "dealer",
}) => {
  const [activeTab, setActiveTab] = useState(startAtTab)
  const [hideOnlineTab, setHideOnlineTab] = useState(suppressNexmart)

  const { t } = useTranslation()

  const handleNexmartCallback = (hasResults) => {
    if (hasResults === false) {
      setActiveTab("dealer")
      setHideOnlineTab(true)
    }
  }

  const handleTabClick = (tab) => {
    if (tab === "online") {
      window.adf && adf.ClickTrack({}, 2861695, "Online Kaufen", {})
    } else {
      window.adf &&
        adf.ClickTrack({}, 2861695, "Fachhändler in der Nähe finden", {})
    }
    setActiveTab(tab)
  }

  return (
    <section className="">
      <div className="bg-ro-gray-50">
        <div className="mx-auto max-w-screen-2xl">
          <div className="flex flex-row overflow-y-auto px-grid">
            {hideOnlineTab !== true && (
              <button
                key="buyTabNexmart"
                className={` btn whitespace-nowrap ml-px ${
                  activeTab === "online"
                    ? "bg-ro-white pointer-events-none btn border-y-2 border-t-ro-red border-b-ro-white"
                    : "text-ro-gray-600 bg-ro-gray-100 btn hover:bg-ro-gray-150 hover:text-ro-gray-900 border-y-2 border-y-ro-gray-100 hover:border-y-ro-gray-150"
                }`}
                onClick={() => handleTabClick("online")}
              >
                {t("generic.products.buy_online")}
              </button>
            )}
            <button
              key="buyTabDealer"
              className={` btn whitespace-nowrap ml-px ${
                activeTab === "dealer"
                  ? "bg-ro-white pointer-events-none btn border-y-2 border-t-ro-red border-b-ro-white"
                  : "text-ro-gray-600 bg-ro-gray-100 btn hover:bg-ro-gray-150 hover:text-ro-gray-900 border-y-2 border-y-ro-gray-100 hover:border-y-ro-gray-150"
              }`}
              onClick={() => handleTabClick("dealer")}
            >
              {t("generic.products.find_dealer")}
            </button>
          </div>
        </div>
      </div>
      <div className="w-full mx-auto">
        <div
          key="tabPanelOnline"
          className={` ${activeTab === "online" ? "block" : "hidden"}`}
        >
          {sku && !suppressNexmart && (
            <>
              <NexmartPanel
                sku={sku}
                retailersFoundCallback={handleNexmartCallback}
              />
            </>
          )}
        </div>
        <div
          key="tabPanelDealer"
          className={` ${activeTab === "dealer" ? "block" : "hidden"}`}
        >
          <DealerFinder containerWidth="100%" containerHeight="75vh" />
        </div>
      </div>
    </section>
  )
}

export default BuyProductPanel
