import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar as faFilledStar } from "@fortawesome/sharp-solid-svg-icons"

const RatingBar = ({
  className,
  totalRatings,
  levelName,
  totalLevelRatings,
}) => {
  let barLength = (totalLevelRatings / totalRatings) * 100 + "%"
  return (
    <div className={`flex flex-row ${className}`}>
      {levelName}
      <FontAwesomeIcon
        icon={faFilledStar}
        size="1x"
        className="inline w-4 h-6 ml-1 align-middle text-ro-red"
      />
      <div className="flex items-center flex-grow ml-4">
        <div className="h-1 bg-ro-red" style={{ width: barLength }}></div>
      </div>
      <span className="inline-block w-12 ml-4 ">{totalLevelRatings}</span>
    </div>
  )
}

export default RatingBar
