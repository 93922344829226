import React, { useState, useEffect } from "react"
import DealerMap from "gatsby-theme-rothenberger/src/components/modules/DealerMap"
import KlaroConsentInfo from "./KlaroConsentInfo"

import useKlaroConsentable from "../../hooks/useKlaroConsentable"

const KLARO_GOOGLEMAPS_APP_KEY = "functional_widgets"

const DealerFinder = ({ containerWidth = null, containerHeight = null }) => {
  const { usageConsented, appInfo, handleKlaroConsent } = useKlaroConsentable(
    KLARO_GOOGLEMAPS_APP_KEY
  )

  const [geolocation, setGeolocation] = useState(null)
  const [error, setError] = useState(null)
  const [searchMode, setSearchMode] = useState("query")
  const [queryString, setQueryString] = useState(null)
  const inPopoverMode = containerWidth !== null

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }
  const onError = (error) => {
    setError("generic.dealer_map.errors." + error.message.replace(/ /g, ""))
  }

  useEffect(() => {
    if (searchMode === "geolocation") {
      const geo = navigator.geolocation
      if (!geo) {
        setError("generic.dealer_map.errors.UserdeniedGeolocation")
        return
      }
      const watcher = geo.watchPosition(onChange, onError)
      return () => geo.clearWatch(watcher)
    }
  }, [searchMode])

  let appName = "Google Maps API"

  return (
    <>
      {usageConsented === false && appInfo ? (
        <div className="p-8">
          <KlaroConsentInfo
            appInfo={appInfo}
            appName={appName}
            handleKlaroConsent={handleKlaroConsent}
            renderWrapper={!inPopoverMode}
          />
        </div>
      ) : (
        <DealerMap
          setQueryString={setQueryString}
          queryString={queryString}
          setSearchMode={setSearchMode}
          searchMode={searchMode}
          coords={geolocation}
          error={error}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
        />
      )}
    </>
  )
}

export default DealerFinder
