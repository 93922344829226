import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const ProductFeatureBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockPadding="py-16"
      blockWidth="container"
      showHeadline="false"
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full mb-4 md:w-1/2 px-grid">
          <Image
            className="w-full h-auto"
            image={block.image}
            aspectRatio="4by3"
          />
        </div>
        <div
          className={`w-full md:w-1/2 px-grid ${
            block.text_position && block.text_position === "left"
              ? "md:order-first"
              : ""
          }`}
        >
          <div className="flex flex-col justify-center w-full h-full mx-auto lg:w-2/3 xl:w-1/2">
            <span className="headline headline-h2 text-ro-red">
              {block.headline}
            </span>
            <Richtext className="mt-4 md:mt-8" text={block.text} />
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ProductFeatureBlock
