import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Icon from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Icon"

const FeaturesBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      showHeadline="true"
      className="fade-in-up animation-delay-1000"
    >
      <div
        className={`flex ${
          block.direction === "vertical" ? "flex-col" : "flex-row"
        } flex-wrap -mx-grid`}
      >
        {block.feature_items?.length > 0 &&
          block.feature_items.map((item, index) => (
            <div
              className={`w-full mt-8 ${
                block.direction === "vertical" ? "" : "md:w-1/2 lg:w-1/3"
              } px-grid`}
              key={index}
            >
              <div
                className={
                  block.direction === "vertical"
                    ? "flex flex-row flex-wrap -mx-grid"
                    : ""
                }
              >
                <div
                  className={
                    block.direction === "vertical" ? "flex px-grid" : ""
                  }
                >
                  <span className="inline-grid w-20 h-20 mb-4 overflow-hidden border-2 rounded-full bg-ro-red border-ro-red">
                    {item.custom_icon?.filename?.length > 0 ? (
                      <Image image={item.custom_icon} aspectRatio="1by1" />
                    ) : (
                      item.icon?.icon && (
                        <Icon
                          icon={item.icon}
                          size="3x"
                          className="block mx-auto mt-3 text-ro-white"
                        />
                      )
                    )}
                  </span>
                </div>
                <div
                  className={
                    block.direction === "vertical" ? "flex-1 px-grid" : ""
                  }
                >
                  <span
                    className="block mb-2 text-xl font-bold"
                    dangerouslySetInnerHTML={{
                      __html: item.title.replace("|", "<br />"),
                    }}
                  />
                  <Richtext text={item.text} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default FeaturesBlock
