import React, { useState, useEffect } from "react"
import axios from "axios"

import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

const JobListingBlock = ({ block }) => {
  const filterByTargetGroup = block.target_group
  const groupByTargetGroups = block.group_by_target_groups
  const [jobs, setJobs] = useState(null)
  const [clusteredJobs, setClusteredJobs] = useState(null)

  useEffect(() => {
    axios.get(process.env.JOBLISTING_API_URL).then((res) => {
      if (res.data.rss?.channel?.item?.length > 0) {
        let relevantJobs = res.data.rss.channel.item
        if (filterByTargetGroup !== "") {
          relevantJobs = relevantJobs.filter(
            (job) =>
              job.heroes_beschaeftigungsart.indexOf(filterByTargetGroup) !== -1
          )
        }
        setJobs(relevantJobs)
        if (groupByTargetGroups && groupByTargetGroups.length > 0) {
          let clusters = []
          groupByTargetGroups.forEach((element) => {
            clusters.push({
              headline: element,
              jobs: relevantJobs.filter(
                (job) => job.heroes_beschaeftigungsart.indexOf(element) !== -1
              ),
            })
          })
          setClusteredJobs(clusters)
          setJobs(null)
        }
      } else {
        setJobs([])
      }
    })

    return () => {
      setJobs(null)
    }
  }, [])

  return (
    <section className="job_listing_block pb-10">
      <div className="block-w-container">
        {jobs && jobs.length > 0 && (
          <ul className="flex flex-row flex-wrap -mx-grid">
            {jobs.map((job) => (
              <li className="py-2 group w-full md:w-1/2 px-grid flex">
                <a
                  className="flex-1 block p-5 mb-5 border-ro-gray-100 hover:border-ro-red border"
                  target="_blank"
                  rel="noreferrer"
                  href={job.heroes_stellenAnsichtLink}
                >
                  <h3 className="font-bold group-hover:text-ro-red">
                    {job.title}
                  </h3>
                  <div className="text-sm text-ro-gray-400">
                    {job.heroes_beschaeftigungsart}, Bereich{" "}
                    {job.heroes_aufgabenbereich}, Standort{" "}
                    {job.heroes_einsatzort}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        )}

        {clusteredJobs && clusteredJobs.length > 0 && (
          <>
            {clusteredJobs.map((cluster) => (
              <>
                <Headline headline={cluster.headline} headlineLevel="h3" />
                {cluster.jobs && cluster.jobs.length > 0 && (
                  <ul className="flex flex-row flex-wrap -mx-grid">
                    {cluster.jobs.map((job) => (
                      <li className="py-2 group w-full md:w-1/2 px-grid flex">
                        <a
                          className="flex-1 block p-5 mb-5 border-ro-gray-100 hover:border-ro-red border"
                          target="_blank"
                          rel="noreferrer"
                          href={job.heroes_stellenAnsichtLink}
                        >
                          <h3 className="font-bold group-hover:text-ro-red">
                            {job.title}
                          </h3>
                          <div className="text-sm text-ro-gray-400">
                            {job.heroes_beschaeftigungsart}, Bereich{" "}
                            {job.heroes_aufgabenbereich}, Standort{" "}
                            {job.heroes_einsatzort}
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ))}
          </>
        )}
      </div>
    </section>
  )
}

export default JobListingBlock
