import React, { useState } from "react"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
//import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/sharp-light-svg-icons"

const ProductHeaderBlock = ({ block }) => {
  /* const [variantsIsOpenState, setVariantsIsOpenState] = useState(false) */

  return (
    <BlockWrapper
      block={block}
      blockPadding="py-10"
      blockWidth="container"
      showHeadline="false"
    >
      <div className="flex flex-row flex-wrap justify-between">
        <div className="w-full lg:w-auto">
          <Headline headlineLevel="h4" tagName="h2" headline={block.headline} />
        </div>
        <div className="w-full mt-4 lg:mt-0 lg:w-auto">
          {/*  <Button className="mb-4 mr-4" btnType="outlineRed" link="/">
            Merken
            <Favorite16 className="ml-8 h-6 inline-block -mt-0.5 align-middle" />
          </Button>
          <Button className="mb-4 mr-4" btnType="outlineRed" link="/">
            Vergleichen
            <Renew16 className="ml-8 h-6 inline-block -mt-0.5 align-middle" />
          </Button> */}
          <Button btnType="primary" link="#variants">
            {block.call_to_action_text}
            <FontAwesomeIcon
              icon={faArrowDown}
              size="1x"
              className="inline-block h-6 ml-8 -mt-0.25 align-middle"
            />
          </Button>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ProductHeaderBlock
