import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/sharp-light-svg-icons"
import { faStar as faFilledStar } from "@fortawesome/sharp-solid-svg-icons"

const RatingStars = ({ rating, maxrating = 5 }) => {
  let stars = []
  for (let i = 0; i < maxrating; i++) {
    stars.push({ name: "star" })
  }

  return (
    <>
      {stars.map((star, index) =>
        index < Math.round(rating) ? (
          <FontAwesomeIcon
            icon={faFilledStar}
            size="1x"
            className="inline w-4 h-4 -mt-1 align-middle text-s-orange"
          />
        ) : (
          <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="inline w-4 h-4 -mt-1 align-middle text-s-ro-gray-dark"
          />
        )
      )}
    </>
  )
}

export default RatingStars
