import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/sharp-light-svg-icons"

import ProductCard from "../molecules/ProductCard"

const ProductCardsBlock = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides =
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4

  if (view === "grid") {
    return (
      <BlockWrapper block={block} blockWidth="container" showHeadline="false">
        <div className="flex flex-row items-baseline justify-between pb-10">
          {block.headline && (
            <Headline
              headline={block.headline}
              headlineLevel="h4"
              tagName="h2"
            />
          )}
          {block.link_text && (
            <Link className="ml-4" link={block.link}>
              {block.link_text}
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                className="inline-block ml-2 -mt-0.5 align-middle"
              />
            </Link>
          )}
        </div>
        <GridWrapper showSlides={showSlides}>
          {block.product_items?.length > 0 &&
            block.product_items.map((item, index) => (
              <div className="flex w-full px-grid lg:w-1/4" key={index}>
                <ProductCard item={item} />
              </div>
            ))}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block} blockWidth="container" showHeadline="false">
        <div className="flex flex-row items-baseline justify-between pb-10">
          {block.headline && (
            <Headline
              headline={block.headline}
              headlineLevel="h4"
              tagName="h2"
            />
          )}
          {block.link_text && (
            <Link className="ml-4" link={block.link}>
              {block.link_text}
              <FontAwesomeIcon
                icon={faArrowRight}
                size="1x"
                className="inline-block ml-2 -mt-0.5 align-middle"
              />
            </Link>
          )}
        </div>
        <SliderWrapper showSlides={showSlides}>
          {block.product_items?.length > 0 &&
            block.product_items.map((item, index) => (
              <div className="flex w-full px-grid" key={index}>
                <ProductCard item={item} />
              </div>
            ))}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default ProductCardsBlock
