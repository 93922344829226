import React from "react"

import { useTranslation } from "react-i18next"

const KlaroConsentInfo = ({
  appInfo,
  appName,
  handleKlaroConsent,
  renderWrapper = false,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {renderWrapper ? (
        <section className="py-10 block-w-container">
          <div className="text-left">
            <div className="prose">
              <h3>{t("generic.user_consent.usage_consent_needed")}</h3>
              <p>
                <b>
                  {appName?.length > 0 && appName + " – "}
                  {appInfo.get("title")}
                </b>
                : {appInfo.get("description")}
              </p>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  handleKlaroConsent(e)
                }}
              >
                {t("generic.user_consent.allow_usage")}
              </button>
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center">
          <div className="prose">
            <h3>{t("generic.user_consent.usage_consent_needed")}</h3>
            <p>
              <b>
                {appName?.length > 0 && appName + " – "}
                {appInfo.get("title")}
              </b>
              : {appInfo.get("description")}
            </p>
            <button
              className="btn btn-primary"
              onClick={(e) => {
                handleKlaroConsent(e)
              }}
            >
              {t("generic.user_consent.allow_usage")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default KlaroConsentInfo
