import React, { useState } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import BlockHeader from "../modules/BlockHeader"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import Drawer from "../modules/Drawer"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList } from "@fortawesome/sharp-light-svg-icons"
import { Link } from "gatsby"

import { tv } from "tailwind-variants"

import MemoizedComponentWrapper from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/technical/MemoizedComponentWrapper"

const tooltipPositionVariants = tv({
  base: "absolute z-10 left-0 transform rounded min-w-56 bottom-3",
  variants: {
    type: {
      left: "-translate-x-[2rem]",
      center: "-translate-x-1/2",
      right: "-translate-x-[calc(100%-2rem)]",
    },
  },
  defaultVariants: { type: "center" },
})
const tooltipArrowPositionVariants = tv({
  base: "absolute z-10 w-3 transform -translate-x-1/2 top-full text-ro-gray-900",
  variants: {
    type: {
      left: "left-[2rem]",
      center: "left-1/2",
      right: "left-[calc(100%-2rem)]",
    },
  },
})

const ToolTip = ({ title, description, xposition }) => {
  let positionType = "center"
  if (xposition < 10) {
    positionType = "left"
  } else if (xposition > 90) {
    positionType = "right"
  }
  return (
    <div className={tooltipPositionVariants({ type: positionType })}>
      <div className="relative hidden p-4 rounded text-ro-sm bg-ro-gray-900 text-ro-gray-50 group-hover:block">
        <span className="block font-bold">{title}</span>
        <span className="mt-1 line-clamp-4">{description}</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 6"
          fill="currentColor"
          className={tooltipArrowPositionVariants({ type: positionType })}
        >
          <title>Down</title>
          <path d="M0 0 L6 6 L12 0" />
        </svg>
      </div>
    </div>
  )
}

const PromoPinMediaBlock = ({ block }) => {
  let [isMobileOpen, setIsMobileOpen] = useState(false)

  return (
    <BlockWrapper block={block}>
      <BlockHeader
        kicker={block.kicker}
        headline={block.headline}
        block={block.subheadline}
        text={block.text}
        links={block.links}
        className="pb-2 md:pb-8"
      />
      <div className="relative">
        <MemoizedComponentWrapper>
          <Image
            image={block.media.augmentableImage}
            aspectRatio="2by1"
            className="block w-full aspect-[2/1]"
          />
          <div className="absolute inset-0 hidden lg:block">
            <div className="flex w-full h-full">
              {block.media?.hotspots?.length > 0 &&
                block.media?.hotspots.map((hotspot, index) => (
                  <Link
                    className="absolute block w-8 h-8 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-ro-red border border-ro-red hover:bg-ro-red-600 hover:border-ro-red-600 active:bg-ro-red-800 active:border-ro-red-800 focus-visible:outline focus-visible:outline-ro-white focus-visible:outline-1 focus-visible:-outline-offset-[3px] focus:outline focus:outline-ro-white focus:outline-1 focus:-outline-offset-[3px] group hover:z-10"
                    style={{ left: hotspot.x + "%", top: hotspot.y + "%" }}
                    to={hotspot.link}
                    key={index}
                  >
                    <div className="relative h-0 ml-4">
                      <ToolTip
                        title={hotspot.title}
                        description={hotspot.description}
                        xposition={hotspot.x}
                      />
                    </div>
                    <span className="block w-[0.625rem] h-[0.625rem] m-[0.625rem] rounded-full bg-ro-white"></span>
                  </Link>
                ))}
            </div>
          </div>
          <div className="lg:hidden">
            <Button
              btnType="secondary"
              className="absolute bottom-0 right-0 z-10 xlg:hidden"
              onClick={() => setIsMobileOpen(true)}
            >
              <FontAwesomeIcon
                icon={faList}
                size="1x"
                className="inline-block w-4 h-4"
              />
            </Button>
          </div>
        </MemoizedComponentWrapper>
      </div>
      {block?.description?.length > 0 && (
        <div className="mt-2 text-sm leading-5 text-ro-gray-600 lg:w-1/2">
          {block.description}
        </div>
      )}
      <Drawer isOpen={isMobileOpen} setIsOpen={setIsMobileOpen}>
        <MemoizedComponentWrapper>
          {block.media?.hotspots?.length > 0 &&
            block.media?.hotspots.map((hotspot, index) => (
              <Link
                className="block p-4 mb-4 text-left transition-colors duration-300 border bg-gray-50 border-ro-gray-200 hover:bg-ro-gray-75 hover:border-ro-gray-300"
                key={index}
                to={hotspot.link}
              >
                <div className="flex flex-row">
                  <div className="block">
                    {hotspot.hotspot_image && (
                      <Image
                        className="w-16 h-16"
                        aspectRatio="1by1"
                        fitIn={true}
                        image={hotspot.hotspot_image}
                      />
                    )}
                  </div>
                  <div className="w-auto ml-4">
                    <span className="block mb-2 font-bold">
                      {hotspot.title}
                    </span>
                    <span className="block">{hotspot.description}</span>
                  </div>
                </div>
              </Link>
            ))}
        </MemoizedComponentWrapper>
      </Drawer>
    </BlockWrapper>
  )
}

export default PromoPinMediaBlock
