import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocation as faLocationDotFilled } from "@fortawesome/sharp-solid-svg-icons"
import { faCompass } from "@fortawesome/sharp-light-svg-icons"

const DealerSearch = ({ activeAddress, setQueryString, setSearchMode }) => {
  const [value, setValue] = useState(activeAddress)

  const { t } = useTranslation()

  const handleInput = (event) => setValue(event.target.value)

  useEffect(() => {
    if (activeAddress) {
      setValue(activeAddress)
    }
  }, [activeAddress])

  const handleDetect = (event) => {
    event.preventDefault()
    setSearchMode("geolocation")
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (value && value !== "") {
      setQueryString(value)
      setSearchMode("query")
    }
  }

  return (
    <div className="py-block block-w-container">
      <form
        className="flex flex-wrap w-full flex-column lg:w-2/3"
        onSubmit={handleSubmit}
      >
        <input
          className="w-full px-4 py-3 text-sm text-ro-gray-900 placeholder-ro-gray-300 border border-ro-gray-100 md:w-1/2 xl:w-2/3"
          type="text"
          name="location_query"
          placeholder={t("generic.dealer_map.search_address_placeholder")}
          value={value}
          onChange={handleInput}
        />
        {value && value !== "" && value !== activeAddress ? (
          <div className="w-full md:w-1/2 xl:w-1/3">
            <button
              type="submit"
              name="search-by-text"
              className="w-full btn btn-primary"
            >
              <FontAwesomeIcon
                icon={faLocationDotFilled}
                size="1x"
                className="inline-block"
              />
              &nbsp;{t("generic.dealer_map.search_address")}
            </button>
          </div>
        ) : (
          <div className="w-full md:w-1/2 xl:w-1/3">
            <button
              type="button"
              name="search-by-detection"
              className="w-full btn btn-primary"
              onClick={handleDetect}
            >
              <FontAwesomeIcon
                icon={faCompass}
                size="1x"
                className="inline-block"
              />
              &nbsp;{t("generic.dealer_map.detect_address")}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default DealerSearch
