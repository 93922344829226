import React, { useState, useEffect } from "react"
import axios from "axios"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const NexmartBlock = ({ block }) => {
  const [retailers, setRetailers] = useState(null)

  useEffect(() => {
    axios
      .get(process.env.NEXMART_API_URL, {
        auth: {
          username: process.env.NEXMART_USERNAME,
          password: process.env.NEXMART_PASSWORD,
        },
        params: {
          services: "online",
          supplier: process.env.NEXMART_SUPPLIER,
          "pid[1]": block.sku,
        },
      })
      .then((res) => {
        setRetailers(res.data.retailers)
      })

    return () => {
      setRetailers(null)
    }
  }, [block.sku])

  // useEffect(() => {
  //   console.log(retailers)
  // }, [retailers])

  if (retailers && retailers.length < 1) return null

  return (
    <BlockWrapper block={block} blockWidth="container" showHeadline="false">
      <Headline
        headline={block.headline}
        headlineLevel="h4"
        tagName="h2"
        id="nexmartRetailers"
      />
      <div className="flex flex-row flex-wrap mt-8 -mx-grid">
        {retailers &&
          retailers?.map((retailer, index) => (
            <div className="w-full px-grid lg:w-1/3" key={index}>
              <a
                href={retailer.products[0].onlineDeeplink}
                target="_blank"
                rel="noopener noreferrer"
                className="border-ro-gray-100 hover:border-ro-red border h-56 flex justify-center items-center p-8"
              >
                <Image
                  className="h-44 w-full object-contain"
                  image={retailer.logoUrl}
                />
              </a>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default NexmartBlock
