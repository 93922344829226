import React from "react"

import { AdvancedVideo } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"

const CloudinaryVideoPlayer = ({ videoFilename, posterImageUrl = null }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.CLOUDINARY_NAME,
    },
  })

  const videoRecord = cld.video(videoFilename.split(".")[0])
  const posterImage =
    posterImageUrl || cld.video(videoFilename).format("jpg").toURL()

  return (
    <div>
      <AdvancedVideo poster={posterImage} cldVid={videoRecord} controls />
    </div>
  )
}

export default CloudinaryVideoPlayer
