// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   team_items -> bloks /
// end of automatic

import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"

import TeamCard from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/TeamCard"
import shuffleArray from "@yumgmbh/gatsby-theme-yum-components/src/utils/shuffleArray"

const TeamBlock = ({ block }) => {
  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides =
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4

  let shuffle = block.shuffle?.length > 0 ? block.shuffle : false

  if (shuffle === true) {
    shuffleArray(block.team_items)
  }

  if (view === "grid") {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <GridWrapper showSlides={showSlides}>
          {block.team_items?.length > 0 &&
            block.team_items.map((item, index) => (
              <div
                className={`block w-full mt-8 sm:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <TeamCard item={item} />
              </div>
            ))}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <SliderWrapper uid={block._uid} showSlides={showSlides}>
          {block.team_items?.length > 0 &&
            block.team_items.map((item, index) => (
              <div className={`block w-full mt-8 px-grid`} key={index}>
                <TeamCard item={item} />
              </div>
            ))}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default TeamBlock
