// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   quote -> textarea / Zitat
//   author -> text / Autor
//   author_description -> text / Beschreibung des Autors
//   image -> asset / Bild
// end of automatic

import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import ImageReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/ImageReveal"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"

const QuoteBlock = ({ block }) => {
  return (
    <>
      {block.quote && block.quote.length > 0 && (
        <BlockWrapper block={block} blockWidth="content">
          <div className="flex flex-row flex-wrap -mx-grid">
            {block.image?.filename && (
              <div className="w-full lg:w-1/3 px-grid">
                <ImageReveal>
                  <Image
                    className="w-full h-auto max-w-xs mx-auto"
                    image={block.image}
                  />
                </ImageReveal>
              </div>
            )}
            <div
              className={`w-full ${
                block.image?.filename && "lg:w-2/3 pt-8 lg:pt-0"
              } px-grid`}
            >
              <blockquote className="pl-12">
                <HeadlineReveal>
                  <p className="relative block text-lg font-bold leading-relaxed text-ro-red">
                    {/* <span className="absolute -mt-12 -ml-16 italic text-8xl"></span> */}
                    {block.quote}
                    {/* <span className="absolute -mt-16 italic font-bold text-8xl text-ro-red"></span> */}
                  </p>
                </HeadlineReveal>
                {block.author?.length > 0 && (
                  <footer className="mt-8">
                    <TextReveal>
                      <span className="block font-bold">{block.author}</span>
                    </TextReveal>
                    <TextReveal>
                      <span className="block">{block.author_description}</span>
                    </TextReveal>
                  </footer>
                )}
              </blockquote>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default QuoteBlock
