import React from "react"

const DividerBlock = ({ block }) => {
  return (
    <section className="divider-block">
      <div className={`block-w-container ${block.className}`}>
        <span className="block w-full border-t border-ro-gray-100"></span>
      </div>
    </section>
  )
}

export default DividerBlock
