import React, { useState } from "react"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components//atoms/Image"

const BenefitsBlock = ({ block }) => {
  const [activeBenefitState, setActiveBenefitState] = useState(null)

  return (
    <BlockWrapper showHeadline="true" block={block}>
      <div
        className={`flex flex-row flex-wrap -mx-grid ${
          block.headline?.length > 0 ? "mt-8" : ""
        }`}
      >
        {block.benefit_items?.length > 0 &&
          block.benefit_items.map((item, index) => (
            <div className="w-full mb-8 px-grid md:w-1/2">
              <div className="flex flex-row">
                <div className="mr-4">
                  <Image className="w-16 m-auto lg:w-20" image={item.image} />
                </div>
                <div className="">
                  <span className="block mb-0 font-bold text-ro-red">
                    {item.title}
                  </span>
                  <Richtext text={item.text} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default BenefitsBlock
